.home_container{
    display: flex;
    justify-content: center;
}
.home_content{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.homie{
    /* background-color: aliceblue; */
    min-width: 1024px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.selectfield{
    width: 350px;
}


@media screen and (max-width:700px)  {
    
    .homie{
        min-width: 300px;
    }
    .cover_photo{
        height: 320px;
    }
    .homie h4{
        font-size: medium;
        
    }
    .homie p{
        font-size:  medium !important;
        padding: 0 15px 0 15px;
    }
}