.Orders_container{
    display: flex;
    justify-content: center;
    background-color: rgba(219, 224, 224, 0.614);
}
.orders_content{
    height: 100vh;
    overflow-y: scroll;
    padding-top: 80px !important;
    width: fit-content;
}
.orders_content::-webkit-scrollbar{
    display: none;
    
    
}
.orders_container::-webkit-scrollbar-thumb{
    background-color: rgb(102, 101, 100);
}
.orders_list{
    background-color: white;
    min-width: 720px;
    border-radius: 10px;
}
.margin h6{
    margin-bottom: 2px;
}
.padding{
    padding: 0 40px 0 40px;
}

@media screen and (max-width:600px) {
    .orders_content{
        width: fit-content;
    }
   .orders_list{
    min-width: 300px;
    margin: 10px 40px 10px 40px;
    border-radius: 10px;
   }
   .padding{
    padding: 0 30px;
}

    
}

@media screen and (max-width:900px) {
    .orders_container::-webkit-scrollbar{
        display: none;
    }
}