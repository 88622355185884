@import url('https://fonts.googleapis.com/css2?family=Andada+Pro:ital,wght@0,500;1,400;1,500;1,600&family=Comfortaa:wght@400;700&display=swap');

.copyright-container{
    border-top: 1px solid rgba(197, 193, 193, 0.797);
    font-family: 'Comfortaa';
    font-size: small;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    padding-left: 35px;
    padding-right: 35px;
    width: 100%;
}
.copyright-logo{
    width: 160px;
}