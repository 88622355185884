.otp_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
}

.otpbox {
    width: 400px;
    background-color: aliceblue;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 550px;
    border-radius: 30px;
    box-shadow: 1px 1px 15px;
}

/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
} */
.otp-input-root{
    height: 165px !important;
}
.otp-group{
    display: flex;
    flex-direction: column;
    align-items: center;
}
