

.collection-wrapper{
    width: 100%;
    height: auto;
    background-color: rgb(26, 25, 25);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 2rem 0;
}
.collection-heading{
    margin: 2rem 0 0 0;
}
.collection-content{
    display: flex;
    justify-content: center;
}
.collection-caption h5{
    color: #68ff63;
    text-align: center;
}


@media screen and (max-width:600px) {
    .collection-content{
        max-width: 360px;
        overflow-x: scroll;
        justify-content: start;
    }
}