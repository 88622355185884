.acc_del_top-panel{
    display: flex;
    margin: 30px 30px 0 30px;
    justify-content: center;
    cursor: pointer;
    
}
.acc_email-login{
    border-radius: 20px;
    padding: 10px 40px 10px 40px;
}
.acc_phone-login{
    border-radius: 20px;
    padding: 10px 40px 10px 40px;
}
.bg-white{
    background-color: white;
}
.emaillogin-content{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.phonelogin-content{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.emaillogin-content h5{
    margin-bottom: 0;
}
.phonelogin-content h5{
    margin-bottom: 0;
}
.off{
    display: none;
}